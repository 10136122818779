<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1>
        <span>{{ $t("account") }} &ndash;</span>
        {{ $t("edit") }}
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'Account' }"></BackButton>

        <form class="mt-5" @submit.prevent="submit">
          <CustomInput v-model="form.name" :label="$t('name')"></CustomInput>

          <CustomInput
            v-model="form.emailAddress"
            :label="$t('email')"
            :type="'email'"
          ></CustomInput>

          <CustomInput
            v-model="form.phoneNumber"
            :label="$t('phone')"
            :type="'phone'"
          ></CustomInput>

          <b-field :label="$tc('language', 1)" label-for="language" horizontal>
            <b-select id="language" v-model="form.language" required>
              <option v-for="l in languages" :key="l.id" :value="l['@id']">
                {{ l.isoName }}
              </option>
            </b-select>
          </b-field>

          <b-button
            type="is-primary"
            native-type="submit"
            icon-left="content-save"
            :loading="loading"
          >
            {{ $t("save") }}
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BackButton from "../../components/BackButton";
import CustomInput from "../../components/forms/CustomInput.vue";

export default {
  name: "Account",
  components: { BackButton, CustomInput },
  data() {
    return {
      loading: true,
      user: null,
      form: {
        name: null,
        emailAddress: null,
        phoneNumber: null,
        language: null,
      },
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
      languages: (state) => state.global.languages,
    }),
  },
  mounted() {
    this.getUser(this.authUser.id).then((user) => {
      this.user = user;
      this.form.name = user.name;
      this.form.emailAddress = user.emailAddress;
      this.form.phoneNumber = user.phoneNumber;
      this.form.language = user.language["@id"];
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getUser", "logout", "updateUser"]),
    submit() {
      this.loading = true;

      this.updateUser({
        id: this.user.id,
        user: this.form,
      })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("account_updated"),
            type: "is-primary",
          });

          if (this.user.emailAddress !== this.form.emailAddress) {
            alert(this.$t("login_required"));
            this.logout();
            this.$router.push({ name: "Login" });
            return;
          }

          this.$router.push({ name: "Account" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
